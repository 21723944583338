import { ForgeMusicExperience } from './commentaryDataTypes';
import {
    Asset,
    AssociatedEntity,
    AssociatedEntityEntityType,
    CreateExperienceInput,
    ExperienceState,
    ExperienceType,
} from '@amzn/mousai-service-client';
import { AssetDestination, uploadMedia } from '../assetUtil';
import { fetchGetPresignedS3DownloadUrl } from '../fetchUtil';
import MousaiClient from '../mousaiUtil';

export interface MousaiAPIResult {
    experienceId: string;
    message: any;
}

export async function handleCommentaryFormSave(
    initialExperiences: ForgeMusicExperience[],
    updatedExperiences: ForgeMusicExperience[],
    albumAsin: string,
    artistAsin: string,
): Promise<[MousaiAPIResult[], MousaiAPIResult[]]> {
    const mousaiAPIHelper = new MousaiAPIHelper(albumAsin, artistAsin, initialExperiences);
    const promises: Promise<MousaiAPIResult>[] = [];
    for (const exp of updatedExperiences) {
        if (exp.isForDeletion) {
            promises.push(mousaiAPIHelper.handleExperienceDeletion(exp));
        } else if (!exp.isExperienceInMousai) {
            promises.push(mousaiAPIHelper.handleExperienceCreation(exp));
        } else if (exp.didExperienceChange) {
            promises.push(mousaiAPIHelper.handleExperienceUpdate(exp));
        }
    }
    await Promise.allSettled(promises);
    return [mousaiAPIHelper.successes, mousaiAPIHelper.failures];
}

/**
 * Helper class to determine which API call to apply
 * **Case 1**: Experience exists locally only
 * This is easy - just one call to CREATE API
 *
 * **Case 2**: Experience exists in Mousai and is updated locally
 * Check these 4 fields, which determine which UPDATE APIs should be called:
 * Update Assets API
 * - audioFile
 * - imageFile
 * - displayText
 *
 * Update Metadata API
 * - title
 *
 * **Case 3**: Experience exists in Mousai and was deleted locally
 * Also easy - just delete the experience. We do this by transitioning the state to deleted
 */
class MousaiAPIHelper {
    private readonly albumAsin: string;
    private readonly artistAsin: string;
    private readonly initialExperiences: ForgeMusicExperience[];
    public readonly successes: MousaiAPIResult[];
    public readonly failures: MousaiAPIResult[];

    constructor(albumAsin: string, artistAsin: string, initialExperiences: ForgeMusicExperience[]) {
        this.albumAsin = albumAsin;
        this.artistAsin = artistAsin;
        this.initialExperiences = initialExperiences;
        this.successes = [];
        this.failures = [];
        console.log(this.artistAsin, this.albumAsin, this.initialExperiences); // Logging for now to prevent defined-but-not-used error
    }

    handleExperienceCreation = async (exp: ForgeMusicExperience): Promise<any> => {
        const uploadAssetResult = await this.uploadExperienceAssets(exp);
        if (!uploadAssetResult) {
            return;
        }
        const [audioUrl, imageUrl] = uploadAssetResult;
        const createInput = convertToCreateInputModel(exp, audioUrl, imageUrl, this.albumAsin, this.artistAsin);
        if (!createInput) {
            return;
        }

        try {
            await MousaiClient.createExperience(createInput);
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully created ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error creating ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleExperienceDeletion = async (exp: ForgeMusicExperience): Promise<any> => {
        try {
            await MousaiClient.instance.updateExperienceState(exp.key, { state: ExperienceState.DELETED });
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully deleted ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error deleting ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleExperienceUpdate = async (exp: ForgeMusicExperience): Promise<any> => {
        // TODO: Uncomment this code once the Mousai API & Workflow has been updated to support COMMENTARY experiences
        // const initialExperience = _(this.initialExperiences)
        //     .filter((e) => e.key == exp.key)
        //     .first();
        // if (!initialExperience) {
        //     return;
        // }
        // await Promise.allSettled([
        //     this.handleAssetUpdate(exp, initialExperience),
        //     this.handleMetadataUpdate(exp, initialExperience),
        // ]);

        // For now, we are using the same FSM as New Release Announcements. To fulfill a commentary UPDATE in Forge,
        // we delete the experience and then recreate it. This is only done for the purpose of testing.

        try {
            await this.handleExperienceDeletion(exp);
            await this.handleExperienceCreation(exp);
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully updated ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error updating ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleAssetUpdate = async (updatedExperience: ForgeMusicExperience, initialExperience: ForgeMusicExperience) => {
        const toUpdate: Asset[] = [];
        if (!!updatedExperience.audioFile) {
            const audioUrl = await this.uploadFileToMousai(updatedExperience.audioFile);
            toUpdate.push({
                key: 'MESSAGE',
                contentType: 'AUDIO',
                contentSource: 'HTTP',
                content: audioUrl,
            });
        }
        if (!!updatedExperience.imageFile) {
            const imageUrl = await this.uploadFileToMousai(updatedExperience.imageFile);
            toUpdate.push({
                key: 'BACKGROUND',
                contentType: 'IMAGE',
                contentSource: 'HTTP',
                content: imageUrl,
            });
        }
        if (updatedExperience.displayText !== initialExperience.displayText) {
            toUpdate.push({
                key: 'TITLE',
                contentType: 'TEXT',
                contentSource: 'RAW',
                content: updatedExperience.displayText,
            });
        }
        if (toUpdate.length > 0) {
            // PLACEHOLDER
            console.log('Updating the following assets:', toUpdate);
        }
    };

    handleMetadataUpdate = async (updatedExperience: ForgeMusicExperience, initialExperience: ForgeMusicExperience) => {
        if (updatedExperience.title !== initialExperience.title) {
            // PLACEHOLDER
            console.log('Updating the title metadata:', updatedExperience.title);
        }
    };

    uploadExperienceAssets = async (exp: ForgeMusicExperience): Promise<[string, string] | undefined> => {
        if (!exp.audioFile || !exp.imageFile) {
            return;
        }
        return await Promise.all([this.uploadFileToMousai(exp.audioFile), this.uploadFileToMousai(exp.imageFile)]);
    };

    uploadFileToMousai = async (file: File): Promise<string> => {
        const uploadResult = await uploadMedia(AssetDestination.MOUSAI, file);
        return await fetchGetPresignedS3DownloadUrl(uploadResult.filename);
    };
}

// API Helpers
export function convertToCreateInputModel(
    exp: ForgeMusicExperience,
    audioUrl: string,
    imageUrl: string,
    albumAsin?: string,
    artistAsin?: string,
): CreateExperienceInput | undefined {
    const assets = buildAssets(audioUrl, imageUrl, exp.displayText);
    const associatedEntities = buildAssociatedEntities(exp, artistAsin);
    if (
        !exp.title ||
        exp.isExplicit === undefined ||
        !albumAsin ||
        assets.length === 0 ||
        associatedEntities.length === 0
    ) {
        return;
    }

    return {
        assets: assets,
        associatedEntities: associatedEntities,
        eligibility: {
            isExplicit: exp.isExplicit,
        },
        owner: {
            id: albumAsin,
            idType: 'ASIN',
        },
        state: ExperienceState.DRAFT,
        title: exp.title,
        type: ExperienceType.COMMENTARY,
    };
}

function buildAssociatedEntities(exp: ForgeMusicExperience, artistAsin?: string): Array<AssociatedEntity> {
    const playableEntityType = getPlayableEntityType(exp.associationType);
    if (!artistAsin || !playableEntityType || !exp.associationAsin) {
        return [];
    }
    return [
        {
            entityType: playableEntityType,
            identifier: {
                id: exp.associationAsin,
                idType: 'ASIN',
            },
        },
        {
            entityType: 'ARTIST',
            identifier: {
                id: artistAsin,
                idType: 'ASIN',
            },
        },
    ];
}

function buildAssets(audioUrl: string, imageUrl: string, displayText?: string): Array<Asset> {
    if (!displayText) {
        return [];
    }
    return [
        {
            key: 'BACKGROUND',
            contentType: 'IMAGE',
            contentSource: 'HTTP',
            content: imageUrl,
        },
        {
            key: 'MESSAGE',
            contentType: 'AUDIO',
            contentSource: 'HTTP',
            content: audioUrl,
        },
        {
            key: 'TITLE',
            contentType: 'TEXT',
            contentSource: 'RAW',
            content: displayText,
        },
    ];
}

function getPlayableEntityType(type?: string): AssociatedEntityEntityType | undefined {
    if (type === 'ALBUM') {
        return 'ALBUM';
    } else if (type === 'TRACK') {
        return 'TRACK';
    }
}
