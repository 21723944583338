import { ForgeMusicExperience } from './commentaryDataTypes';

export function isValidForgeExperience(exp: ForgeMusicExperience): boolean {
    // Validate Text fields
    const isAssociationAsinValid = isValidTextValue(exp.associationAsin);
    const isDisplayTextValid = isValidTextValue(exp.displayText);
    const isTitleValid = isValidTextValue(exp.title);
    // Validate Files
    const doesAudioExist = !!exp.audioFile || !!exp.presignedAudioUrl;
    const doesImageExist = !!exp.imageFile || !!exp.presignedImageUrl;
    return isAssociationAsinValid && isDisplayTextValid && isTitleValid && doesAudioExist && doesImageExist;
}

export function isValidTextValue(text?: string): boolean {
    // Text fields must not be empty
    return !!text?.trim();
}

export function isExperienceEligibleToSave(exp: ForgeMusicExperience): boolean {
    /**
     * Experience Save Eligibility:
     *  - If the experience is marked for deletion, then it is eligible to save (so that the deletion applies)
     *  - Otherwise, return whether the experience is valid
     */
    if (exp.isForDeletion) {
        return true;
    }
    return isValidForgeExperience(exp);
}
