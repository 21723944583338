import { ExperienceSearchInput, ExperienceType, MusicExperienceSearchItem } from '@amzn/mousai-service-client';
import { generateUniqueId } from '@amzn/awsui-components-react-v3/uxdg/internal/hooks/use-unique-id';
import { ForgeMusicExperience } from './commentaryDataTypes';
import {
    getArtistAsinFromCommentaryExperience,
    getAudioAssetPresignedUrl,
    getDisplayText,
    getImageAssetPresignedUrl,
    getPlayableEntity,
} from '../mousaiUtil';
import { isValidTextValue } from './validation';
import { CommentaryAsins } from '../../components/artist-commentaries/EditArtistCommentary';

export function convertMousaiDataToForgeExperiences(commentaries: MusicExperienceSearchItem[]): ForgeMusicExperience[] {
    const experiences: ForgeMusicExperience[] = [];
    commentaries.forEach((exp) => {
        const subForm = convertMousaiDataToForgeExperience(exp);
        if (subForm) {
            experiences.push(subForm);
        }
    });
    return experiences;
}

export function createNewForgeExperience(associationType: string, associationAsin?: string): ForgeMusicExperience {
    const uuid = generateUniqueId();
    return {
        didExperienceChange: false,
        isForDeletion: false,
        isExperienceInMousai: false,
        key: uuid,
        associationType: associationType,
        associationAsin: associationAsin,
        isExplicit: false,
    };
}

export function buildSearchCommentaryForAsinQuery(asin: string): ExperienceSearchInput {
    return {
        query: {
            owner: {
                id: asin,
                idType: 'ASIN',
            },
            experienceTypes: [ExperienceType.COMMENTARY],
        },
        includeAssets: true,
    };
}

function convertMousaiDataToForgeExperience(exp: MusicExperienceSearchItem): ForgeMusicExperience | undefined {
    if (!exp.assets) {
        return;
    }
    const associatedPlayableEntity = getPlayableEntity(exp);
    return {
        didExperienceChange: false,
        isForDeletion: false,
        isExperienceInMousai: true,
        key: exp.experienceId,
        associationAsin: associatedPlayableEntity?.identifier.id,
        associationType: associatedPlayableEntity?.entityType,
        displayText: getDisplayText(exp),
        isExplicit: exp.eligibility.isExplicit,
        presignedAudioUrl: getAudioAssetPresignedUrl(exp),
        presignedImageUrl: getImageAssetPresignedUrl(exp),
        title: exp.title,
    };
}

export function retrieveAsinsFromMousai(exp: MusicExperienceSearchItem): [CommentaryAsins, boolean] {
    const albumAsin = exp.owner.id;
    const artistAsin = getArtistAsinFromCommentaryExperience(exp);
    const isValidAlbumAsin = isValidTextValue(albumAsin);
    const isValidArtistAsin = isValidTextValue(artistAsin);
    return [
        {
            album: albumAsin,
            artist: artistAsin,
        },
        isValidAlbumAsin && isValidArtistAsin,
    ];
}
