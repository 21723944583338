import {
    AssociatedEntity,
    CreateExperienceInput,
    ExperienceSearchInput,
    MusicExperience,
    MusicExperienceApi,
    MusicExperienceClient,
    MusicExperienceSearchItem,
    UpdateExperienceMetadataInput,
    UpdateExperienceStateInput,
} from '@amzn/mousai-service-client';
import { AxiosRequestConfig } from 'axios';
import { getAuthorizationHeader } from '../auth/AuthGenerator';
import stage from '../configs/stage';
import _ from 'lodash';

const MOUSAI_API_ENDPOINTS: Record<string, string> = {
    alpha: 'https://wwzyt5ae2m.execute-api.us-east-1.amazonaws.com/beta/forge/v1',
    beta: 'https://wwzyt5ae2m.execute-api.us-east-1.amazonaws.com/beta/forge/v1',
    gamma: 'https://vv7l3nqotd.execute-api.us-east-1.amazonaws.com/gamma/forge/v1',
    prod: 'https://f4n5sjdym9.execute-api.us-east-1.amazonaws.com/prod/forge/v1',
};

type SearchMusicExperiencesInput = Omit<ExperienceSearchInput, 'nextToken' | 'maxResults'>;

export default class MousaiClient {
    private static client: MusicExperienceClient;

    private static authInterceptor(config: AxiosRequestConfig<any>) {
        config.headers = {
            ...config.headers,
            Authorization: getAuthorizationHeader(),
        };
        return config;
    }

    private static getEndpoint() {
        if (process.env.REACT_APP_MOUSAI_API_URL) {
            return process.env.REACT_APP_MOUSAI_API_URL;
        }
        const current = stage();
        return MOUSAI_API_ENDPOINTS[current];
    }

    // Lazy initialization of the client
    static get instance(): MusicExperienceApi {
        if (!this.client) {
            this.client = new MusicExperienceClient({
                addAwsV4SigningToRequests: false,
                serviceBaseUrl: this.getEndpoint(),
            });
            this.client.axiosInstance.interceptors.request.use(this.authInterceptor);
        }
        return this.client.musicExperience;
    }

    static async search(
        searchInput: SearchMusicExperiencesInput,
        onProgress?: (current: MusicExperienceSearchItem[]) => void,
    ): Promise<MusicExperienceSearchItem[]> {
        let token: string | undefined;
        let results: MusicExperienceSearchItem[] = [];
        do {
            const input = {
                ...searchInput,
                maxResults: 1000,
                nextToken: token,
            };
            const response = await this.instance.searchExperiences(input);
            if (response.status != 200) {
                throw new Error(`Failed to fetch music experiences: ${response.statusText} (${response.status})`);
            }
            if (response.data.experiences) {
                results = results.concat(response.data.experiences);
                onProgress?.(results);
            }
            token = response.data.nextToken;
        } while (token);
        return results;
    }

    static async updateState(experienceIds: string[], input: UpdateExperienceStateInput) {
        const failures: Record<string, any> = {};
        const promises = experienceIds.map((id) => {
            return MousaiClient.instance
                .updateExperienceState(id, input)
                .then((result) => {
                    if (result.status !== 204) {
                        failures[id] = result.statusText;
                    }
                })
                .catch((error) => (failures[id] = MousaiClient.parseError(error)));
        });
        await Promise.allSettled(promises);
        return failures;
    }

    static async updateMetadata(experienceIds: string[], input: UpdateExperienceMetadataInput) {
        const failures: Record<string, any> = {};
        const promises = experienceIds.map((id) => {
            return MousaiClient.instance
                .updateExperienceMetadata(id, input)
                .then((result) => {
                    if (result.status !== 204) {
                        failures[id] = result.statusText;
                    }
                })
                .catch((error) => (failures[id] = MousaiClient.parseError(error)));
        });
        await Promise.allSettled(promises);
        return failures;
    }

    static async createExperience(input: CreateExperienceInput): Promise<MusicExperience | undefined> {
        const result = await MousaiClient.instance.createExperience(input);
        if (result.status !== 201) {
            throw result.statusText;
        }
        return result.data;
    }

    private static parseError(error: any) {
        if (error.isAxiosError) {
            return error?.response?.data?.message ?? error?.response?.statusText;
        }
        return error;
    }
}

// MARK: Helper functions to retrieve data from the Mousai Models
// https://tiny.amazon.com/t4oizfgq/mousai-swagger-api-spec
export function getAudioAssetPresignedUrl(item: MusicExperienceSearchItem): string | undefined {
    return _(item.assets)
        .filter((a) => a.key == 'MESSAGE')
        .first()?.content;
}

export function getImageAssetPresignedUrl(item: MusicExperienceSearchItem): string | undefined {
    return _(item.assets)
        .filter((a) => a.key == 'BACKGROUND')
        .first()?.content;
}

export function getDisplayText(item: MusicExperienceSearchItem): string | undefined {
    return _(item.assets)
        .filter((a) => a.key == 'TITLE')
        .first()?.content;
}

export function getPlayableEntity(item: MusicExperienceSearchItem): AssociatedEntity | undefined {
    return _(item.associatedEntities)
        .filter((a) => a.entityType === 'ALBUM' || a.entityType === 'TRACK')
        .first();
}

export function getArtistAsinFromCommentaryExperience(item: MusicExperienceSearchItem): string | undefined {
    return _(item.associatedEntities)
        .filter((a) => a.entityType === 'ARTIST')
        .first()?.identifier.id;
}
