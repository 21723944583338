import { Table, TableContentSelector, TableSorting } from '@amzn/awsui-components-react';
import { MusicExperienceTableItem } from '../configs/experiences-table-config';
import { FlashbarItem } from '../components/commons/flash-messages';
import { ExperienceState, ExperienceType } from '@amzn/mousai-service-client';

function filterProperty(prop: any, filteringText: string): boolean {
    if (typeof prop === 'string') {
        return prop.toLowerCase().indexOf(filteringText) >= 0;
    }
    if (Array.isArray(prop)) {
        const array = prop as any[];
        return array.findIndex((item) => filterProperty(item, filteringText)) >= 0;
    }
    if (typeof prop === 'object') {
        for (const subProp in prop) {
            if (filterProperty(prop[subProp], filteringText)) {
                return true;
            }
        }
    }
    return false;
}

export function filteringFunction(item: any, filteringText: string, filteringFields: string[]): boolean {
    filteringText = filteringText.toLowerCase();
    for (const prop in item) {
        if (!filteringFields || filteringFields.length == 0 || filteringFields.indexOf(prop) >= 0) {
            if (filterProperty(item[prop], filteringText)) {
                return true;
            }
        }
    }

    return false;
}

export interface ColumnSetting {
    id?: string;
    width?: number;
    visible?: boolean;
}

export type ColumnDefinitionWithId<T> = Table.ColumnDefinition<T> & { id: string };

export interface TableOptions {
    pageSize: number;
    wrapLines: boolean;
}

export interface MusicExperienceTableOptions extends TableOptions {
    columnDefinitions: Table.ColumnDefinition[];
    contentSelector: TableContentSelector.ContentDescriptionGroup[];
    selectedExperiences: MusicExperienceTableItem[];
    experiences: MusicExperienceTableItem[];
    hasData: boolean;
    loading: boolean;
    working: boolean;
    flashbar: FlashbarItem[];
    filteringText?: string;
    sortingDetail: TableSorting.SortingChangeDetail;
    stateFilter: ExperienceState;
    typeFilter: ExperienceType;
    showScheduleModal: boolean;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
}

export const DEFAULT_TABLE_OPTIONS: TableOptions = {
    pageSize: 15,
    wrapLines: false,
};

export function filterTableOptions(input: TableOptions): TableOptions {
    const keys = Object.keys(DEFAULT_TABLE_OPTIONS) as (keyof TableOptions)[];
    return keys.reduce((obj, key) => {
        return { ...obj, [key]: input[key] };
    }, {}) as TableOptions;
}

export function addToColumnDefinitions(
    columnDefinitions: Table.ColumnDefinition[],
    propertyName: Exclude<keyof ColumnSetting, 'id'>,
    columns?: ColumnSetting[],
): Table.ColumnDefinition[] {
    return columnDefinitions.map((colDef) => {
        const column = (columns || []).find((col) => col.id === colDef.id);
        return {
            ...colDef,
            [propertyName]: column && column[propertyName],
        };
    });
}

export function mapWithColumnDefinitionIds(
    columnDefinitions: Table.ColumnDefinition[],
    propertyName: Exclude<keyof ColumnSetting, 'id'>,
    items: ColumnSetting[typeof propertyName][],
    columns?: ColumnSetting[],
): ColumnSetting[] {
    return columnDefinitions.map(({ id }, i) => {
        const column = (columns || []).find((col) => col.id === id) || {};
        return {
            ...column,
            id,
            [propertyName]: items[i],
        };
    });
}

export function addToContentDescriptionGroups(
    contentDescriptionGroups: TableContentSelector.ContentDescriptionGroup[],
    columns?: ColumnSetting[],
): TableContentSelector.ContentDescriptionGroup[] {
    return contentDescriptionGroups.map((group) => {
        const options = group.options.map((options) => {
            const column = (columns || []).find((col) => col.id === options.id);
            const visible = typeof column?.visible === 'boolean' ? column.visible : true;
            return {
                ...options,
                visible,
            };
        });
        return {
            ...group,
            options,
        };
    });
}

export function mapWithContentSelectionValues(
    columnDefinitions: Table.ColumnDefinition[],
    contentSelection: string[],
    columns?: ColumnSetting[],
): ColumnSetting[] {
    return columnDefinitions.map(({ id }) => {
        const visible = contentSelection.indexOf(id || '') >= 0;
        const column = (columns || []).find((col) => col.id === id) || {};
        return {
            ...column,
            id,
            visible,
        };
    });
}
