import { Table, TableSorting } from '@amzn/awsui-components-react';
import { MusicExperienceSearchItem } from '@amzn/mousai-service-client';
import { ColumnDefinitionWithId } from '../utils/tableUtil';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

export interface MusicExperienceTableItem extends MusicExperienceSearchItem, Table.Item {
    // Used to join fields from MusicExperience and Table.Item - no new fields required
}

function getMessage(item: MusicExperienceSearchItem) {
    return _(item.assets)
        .filter((a) => a.key == 'MESSAGE')
        .first();
}

function getTranscript(item: MusicExperienceSearchItem) {
    return getMessage(item)?.metadata?.transcript ?? '';
}

function getAudio(item: MusicExperienceSearchItem) {
    return getMessage(item)?.content;
}

function getInterlude(item: MusicExperienceSearchItem) {
    return _(item.stormResources)
        ?.filter((r) => r.type === 'INTERLUDE')
        .first()?.id;
}

function getTags(item: MusicExperienceSearchItem) {
    return getMessage(item)?.metadata?.tags?.join();
}

function getAssets(item: MusicExperienceSearchItem) {
    return _(item.stormResources)
        ?.filter((r) => r.type === 'ASSET')
        .map((res) => res.id)
        .value();
}

function getDisplayText(item: MusicExperienceSearchItem) {
    return _(item.assets)
        .filter((a) => a.key == 'TITLE')
        .first()?.content;
}

export const ARTIST_COMMENTARIES_COLUMN_DEFINITIONS: ColumnDefinitionWithId<MusicExperienceTableItem>[] = [
    {
        id: 'container asin',
        header: () => 'Container Asin',
        cell: (item) => <Link to={`/artist-commentaries/${item.owner.id}`}>{item.owner.id}</Link>,
        minWidth: '100px',
    },
    {
        id: 'title',
        header: () => 'Experience Title',
        cell: (item) => item.title,
        minWidth: '100px',
    },
    {
        id: 'id',
        header: () => 'Experience ID',
        cell: (item) => item.experienceId,
        minWidth: '180px',
    },
    {
        id: 'type',
        cell: (item) => item.type,
        header: () => 'Experience Type',
        minWidth: '100px',
    },
    {
        id: 'state',
        header: () => 'State',
        cell: (item) => item.state,
        minWidth: '100px',
    },
    {
        id: 'createdAt',
        header: () => 'Creation Date',
        cell: (item) => DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATETIME_SHORT),
        minWidth: '170px',
    },
    {
        id: 'display text',
        header: () => 'Display Text',
        cell: (item) => getDisplayText(item),
        width: 100,
    },
    {
        id: 'interlude',
        header: () => 'Interlude ID',
        cell: (item) => (
            <div>
                <Link to={`/interludes/${getInterlude(item)}`}>{getInterlude(item)}</Link>
            </div>
        ),
        width: 100,
    },
    {
        id: 'assets',
        header: () => 'Assets',
        cell: (item) => (
            <div>
                [
                {getAssets(item)?.map((asset, idx) => (
                    <Link to={`/assets/${asset}`} key={idx}>
                        {asset}{' '}
                    </Link>
                ))}
                ]
            </div>
        ),
        width: 1000,
    },
];

export const VOICE_COMMENTS_COLUMN_DEFINITIONS: ColumnDefinitionWithId<MusicExperienceTableItem>[] = [
    {
        id: 'id',
        header: () => 'Experience ID',
        cell: (item) => item.experienceId,
        minWidth: '180px',
    },
    {
        id: 'type',
        cell: (item) => item.type,
        header: () => 'Experience Type',
        minWidth: '160px',
    },
    {
        id: 'state',
        header: () => 'State',
        cell: (item) => item.state,
        minWidth: '100px',
    },
    {
        id: 'createdAt',
        header: () => 'Creation Date',
        cell: (item) => DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATETIME_SHORT),
        minWidth: '170px',
    },
    {
        id: 'audio',
        header: () => 'Audio',
        cell: (item) => (
            <a href={getAudio(item)} target="_blank" rel="noreferrer">
                Audio
            </a>
        ),
        width: 70,
        allowLineWrap: true,
    },
    {
        id: 'transcript',
        header: () => 'Transcript',
        cell: (item) => getTranscript(item),
        allowLineWrap: true,
    },
    {
        id: 'tags',
        header: () => 'Tags',
        cell: (item) => getTags(item),
        width: 100,
    },
    {
        id: 'interlude',
        header: () => 'Interlude ID',
        cell: (item) => (
            <div>
                <Link to={`/interludes/${getInterlude(item)}`}>{getInterlude(item)}</Link>
            </div>
        ),
        width: 100,
    },
];

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<MusicExperienceTableItem>[] = [
    { id: 'id', field: 'id' },
    { id: 'type', field: 'type' },
    { id: 'state', field: 'state' },
    {
        id: 'createdAt',
        comparator: (exp1, exp2) =>
            DateTime.fromISO(exp2.createdAt).diff(DateTime.fromISO(exp1.createdAt)).milliseconds,
    },
];

export const FILTERING_FIELDS = ['type'];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main Properties',
        options: [
            { id: 'id', label: 'Experience ID', editable: true, visible: true },
            { id: 'type', label: 'Type', editable: true, visible: true },
            { id: 'state', label: 'State', editable: true, visible: true },
            { id: 'createdAt', label: 'Creation Date', editable: true, visible: true },
            { id: 'audio', label: 'Audio', editable: true, visible: true },
            { id: 'transcript', label: 'Transcript', editable: true, visible: true },
            { id: 'tags', label: 'Tags', editable: true, visible: true },
            { id: 'interlude', label: 'Interlude ID', editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Experiences' },
    { value: 15, label: '15 Experiences' },
    { value: 30, label: '30 Experiences' },
    { value: 100, label: '100 Experiences' },
    { value: 500, label: '500 Experiences' },
    { value: 1000, label: '1000 Experiences' },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter experiences by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear filters',
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter distributions',
    filteringStatusText: 'Loading',
};
