import React from 'react';
import AudioMediaFooter from '../media/AudioMediaFooter';
import { FormSection } from '@amzn/awsui-components-react';
import { ForgeMusicExperience } from '../../utils/commentary/commentaryDataTypes';
import { formatImage } from '../../utils/imageUtil';
import { ArtistCommentaryPreset } from '@amzn/forge-image-processing-types';
import ArtistCommentaryImageMediaFooter from './ArtistCommentaryImageMediaFooter';
import Media from '../media/Media';

export interface BaseMediaProps {
    experience: ForgeMusicExperience;
    onExperienceChanged: (exp: ForgeMusicExperience, isFileUpdate?: boolean) => void;
}

export interface ImageMediaProps extends BaseMediaProps {
    albumImageFile?: File;
}

export interface BaseMediaState {
    isFileLoading: boolean;
    file?: File;
}

export class AudioSection extends React.Component<BaseMediaProps, BaseMediaState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFileLoading: false,
            file: undefined,
        };
    }

    getAudioFileName = (): string => {
        if (!this.state.file && !this.props.experience.presignedAudioUrl) {
            return '';
        } else if (this.state.file?.name) {
            return `${this.state.file.name}:`;
        }
        return '[Audio file from server]';
    };

    handleAudioFileChange = (files: File[]): void => {
        this.setState({ isFileLoading: true });
        if (!files || files.length == 0) {
            this.setState({ file: undefined });
        } else {
            this.setState({ file: files[0] });
            this.props.experience.audioFile = files[0];
            this.props.experience.didExperienceChange = true;
            this.props.onExperienceChanged(this.props.experience, true);
        }
        this.setState({ isFileLoading: false });
    };

    render(): JSX.Element {
        const audioResponsiveColumn = 'col-xxxs-12 col-xxs-4';
        const audioComponent = (
            <div className="awsui-row">
                <h5 className={audioResponsiveColumn}>{this.getAudioFileName()}</h5>
                <Media
                    className={audioResponsiveColumn}
                    presignedUrl={this.props.experience.presignedAudioUrl}
                    selectedFile={this.state.file}
                    type={'AUDIO'}
                />
            </div>
        );
        const audioMediaFooter = (
            <AudioMediaFooter
                loadingFile={this.state.isFileLoading}
                onFileChange={this.handleAudioFileChange}
                multiple={false}
                canUploadFolder={false}
            />
        );

        return (
            <FormSection header="Commentary Audio" footer={audioMediaFooter}>
                {!this.state.isFileLoading && audioComponent}
            </FormSection>
        );
    }
}

export class ImageSection extends React.Component<ImageMediaProps, BaseMediaState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFileLoading: false,
            file: undefined,
        };
    }

    handleImageFileChange = async (files: File[], shouldUseAlbumImage: boolean) => {
        this.setState({ isFileLoading: true });
        if (shouldUseAlbumImage && !!this.props.albumImageFile) {
            this.setState({ file: this.props.albumImageFile });
            this.props.experience.imageFile = this.props.albumImageFile;
            this.props.onExperienceChanged(this.props.experience, true);
        } else {
            if (!files || files.length == 0) {
                this.setState({ file: undefined });
            } else {
                const formattedFile = await this.formatImageFile(files[0]);
                this.setState({ file: formattedFile });
                this.props.experience.imageFile = formattedFile;
                this.props.experience.didExperienceChange = true;
                this.props.onExperienceChanged(this.props.experience, true);
            }
        }
        this.setState({ isFileLoading: false });
    };

    formatImageFile = async (file: File): Promise<File> => {
        const fileName = file.name;
        const rawImage = Buffer.from(await file.arrayBuffer());
        const formatted = await formatImage(rawImage, ArtistCommentaryPreset);
        return new File([await formatted.getBufferAsync(formatted.getMIME())], fileName);
    };

    render(): JSX.Element {
        const imageComponent = (
            <div>
                <Media
                    presignedUrl={this.props.experience.presignedImageUrl}
                    selectedFile={this.state.file}
                    type={'IMAGE'}
                />
            </div>
        );
        const imageMediaFooter = (
            <ArtistCommentaryImageMediaFooter
                albumImageFile={this.props.albumImageFile}
                loadingFile={this.state.isFileLoading}
                onFileChange={this.handleImageFileChange}
            />
        );

        return (
            <FormSection className="col-xxxs-12 col-xxs-4" header="Commentary Image" footer={imageMediaFooter}>
                {!this.state.isFileLoading && imageComponent}
            </FormSection>
        );
    }
}
