import { ForgeMusicExperience } from '../../utils/commentary/commentaryDataTypes';
import { FlashbarItem, itemInfo, itemSuccess, itemWarning } from '../commons/flash-messages';
import React from 'react';
import { Button, ExpandableSection, Flashbar, Form } from '@amzn/awsui-components-react';
import InformationSection from './ArtistCommentaryInformationSection';
import _ from 'lodash';
import { AudioSection, ImageSection } from './ArtistCommentaryMediaSection';

export interface Props {
    experience: ForgeMusicExperience;
    headerText: string;
    onExperienceChanged: (exp: ForgeMusicExperience) => void;
    onExperienceDeleted: (exp: ForgeMusicExperience) => void;
    albumImageFile?: File;
}

export interface State {
    flashbar: FlashbarItem[];
    saving: boolean;
}

function generateInitialState(isExperienceInMousai: boolean): State {
    return {
        flashbar: [
            isExperienceInMousai
                ? itemInfo('Existing experience').isDismissible(false)
                : itemInfo('New experience').isDismissible(false),
        ],
        saving: false,
    };
}

export class ArtistCommentarySubForm extends React.Component<Props, State> {
    private savedExperience: ForgeMusicExperience;

    constructor(props: any) {
        super(props);
        this.state = generateInitialState(props.experience.isExperienceInMousai);
        this.savedExperience = Object.assign({}, this.props.experience);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const didReceiveNewExperience = !_.isEqual(prevProps.experience, this.props.experience);
        if (didReceiveNewExperience) {
            this.setState({
                ...generateInitialState(this.props.experience.isExperienceInMousai),
            });
            this.savedExperience = Object.assign({}, this.props.experience);
        }
    }

    onExperienceChanged = (updatedExperience: ForgeMusicExperience, isFileUpdate = false): void => {
        const hasExperienceChanged = !_.isEqual(updatedExperience, this.savedExperience) || isFileUpdate;
        const flashbar: FlashbarItem[] = [];
        if (!this.props.experience.isExperienceInMousai) {
            this.props.onExperienceChanged(updatedExperience);
            return;
        }
        flashbar.push(itemInfo('Existing experience').isDismissible(false));
        if (hasExperienceChanged) {
            flashbar.push(itemWarning('Unsaved changes').isDismissible(false));
            updatedExperience.didExperienceChange = true;
            this.props.onExperienceChanged(updatedExperience);
        } else {
            flashbar.push(itemSuccess('No changes since last save').isDismissible(false));
        }
        this.setState({ flashbar: flashbar });
    };

    onDeleteClicked = (): void => {
        this.props.onExperienceDeleted(this.props.experience);
    };

    render(): JSX.Element {
        const deleteCommentaryButton: JSX.Element = (
            <Button
                variant="primary"
                onClick={this.onDeleteClicked}
                loading={this.state.saving}
                key={`delete-${this.props.experience.key}`}
            >
                Delete
            </Button>
        );

        return (
            <ExpandableSection header={this.props.headerText} variant="container" expanded={true}>
                <Form actions={[deleteCommentaryButton]}>
                    <Flashbar items={this.state.flashbar}></Flashbar>
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-xxxs-12 col-xxs-8">
                                <InformationSection
                                    experience={this.props.experience}
                                    onExperienceChanged={this.onExperienceChanged}
                                />
                                <AudioSection
                                    experience={this.props.experience}
                                    onExperienceChanged={this.onExperienceChanged}
                                />
                            </div>
                            <div className="col-xxxs-12 col-xxs-4">
                                <ImageSection
                                    albumImageFile={this.props.albumImageFile}
                                    experience={this.props.experience}
                                    onExperienceChanged={this.onExperienceChanged}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </ExpandableSection>
        );
    }
}
