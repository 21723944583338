import React from 'react';
import { ForgeMusicExperience } from '../../utils/commentary/commentaryDataTypes';
import { ArtistCommentarySubForm } from './ArtistCommentarySubForm';

interface Props {
    onAlbumExperienceChanged: (exp: ForgeMusicExperience) => void;
    onAlbumExperienceDeleted: (exp: ForgeMusicExperience) => void;
    onTrackExperienceChanged: (exp: ForgeMusicExperience) => void;
    onTrackExperienceDeleted: (exp: ForgeMusicExperience) => void;
    albumImageFile?: File;
    albumCommentary?: ForgeMusicExperience;
    trackCommentaries: ForgeMusicExperience[];
}

export class ArtistCommentaryFormManager extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <React.Fragment>
                {this.props.albumCommentary ? (
                    <div className="awsui-row" hidden={!this.props.albumCommentary}>
                        <div className="col-xxxs-12">
                            <ArtistCommentarySubForm
                                albumImageFile={this.props.albumImageFile}
                                experience={this.props.albumCommentary}
                                headerText={'Album Commentary'}
                                onExperienceChanged={this.props.onAlbumExperienceChanged}
                                onExperienceDeleted={this.props.onAlbumExperienceDeleted}
                            ></ArtistCommentarySubForm>
                        </div>
                    </div>
                ) : null}
                {this.props.trackCommentaries
                    .filter((commentary) => !commentary.isForDeletion)
                    .map((commentary) => {
                        return (
                            <div className="awsui-row" key={commentary.key}>
                                <div className="col-xxxs-12">
                                    <ArtistCommentarySubForm
                                        albumImageFile={this.props.albumImageFile}
                                        experience={commentary}
                                        headerText={'Track Commentary'}
                                        onExperienceChanged={this.props.onTrackExperienceChanged}
                                        onExperienceDeleted={this.props.onTrackExperienceDeleted}
                                    ></ArtistCommentarySubForm>
                                </div>
                            </div>
                        );
                    })}
            </React.Fragment>
        );
    }
}
