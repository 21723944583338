import React, { createRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Flashbar, Form, Spinner } from '@amzn/awsui-components-react';
import PageHeader, { PageHeaderButton } from '../PageHeader';
import {
    buildSearchCommentaryForAsinQuery,
    convertMousaiDataToForgeExperiences,
    createNewForgeExperience,
    retrieveAsinsFromMousai,
} from '../../utils/commentary/mousaiDataToForgeExperience';
import MousaiClient from '../../utils/mousaiUtil';
import { handleCommentaryFormSave, MousaiAPIResult } from '../../utils/commentary/forgeExperienceToMousaiData';
import { ArtistCommentaryAsinForm } from './ArtistCommentaryAsinForm';
import { ArtistCommentaryFormManager } from './ArtistCommentaryFormManager';
import { ForgeMusicExperience } from '../../utils/commentary/commentaryDataTypes';
import { deepCopy } from '../../utils/jsonUtil';
import { isExperienceEligibleToSave } from '../../utils/commentary/validation';
import { itemError, itemLoading } from '../commons/flash-messages';
import MessageDefinition = Flashbar.MessageDefinition;

export interface CommentaryAsins {
    album?: string;
    artist?: string;
}

interface SaveCommentaryState {
    areValidAsins: boolean;
    areAllCommentariesValid: boolean;
}

interface State {
    addAlbumCommentaryButtonDisabled: boolean;
    addTrackCommentaryButtonDisabled: boolean;
    commentaryAsins: CommentaryAsins;
    canSave: SaveCommentaryState;
    doesCommentaryExistForContainerAsin: boolean;
    flashbar: MessageDefinition[];
    initialExperiences: ForgeMusicExperience[];
    isMousaiExperience: boolean;
    isSaveComplete: boolean;
    loading: boolean;
    trackCommentaries: ForgeMusicExperience[];
    albumImageFile?: File;
    albumCommentary?: ForgeMusicExperience;
}

interface RouteParams {
    containerAsin?: string;
}

type Props = RouteComponentProps<RouteParams>;

const INITIAL_STATE = {
    addAlbumCommentaryButtonDisabled: true,
    addTrackCommentaryButtonDisabled: true,
    albumImageButtonDisabled: true,
    canSave: {
        areValidAsins: false,
        areAllCommentariesValid: false,
    },
    commentaryAsins: {},
    doesCommentaryExistForContainerAsin: true,
    flashbar: [],
    initialExperiences: [],
    isMousaiExperience: false,
    isSaveComplete: false,
    loading: true,
    trackCommentaries: [],
    albumCommentary: undefined,
    albumImageFile: undefined,
};

export default class EditArtistCommentary extends React.Component<Props, State> {
    private readonly bottomOfPageRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);
        this.state = INITIAL_STATE;
        this.bottomOfPageRef = createRef();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.match?.params?.containerAsin !== this.props.match?.params?.containerAsin) {
            // User navigated to a different URL that maps to the same component
            // React does not (by design) unload the component, so we need to clean up the state
            this.loadCommentaryDetails().catch((error) => console.log('Error loading commentary details:', error));
        }
    }

    componentDidMount() {
        this.loadCommentaryDetails().catch((error) => console.log('Error loading commentary details:', error));
    }

    loadCommentaryDetails = async (): Promise<void> => {
        // TODO: Fully distinguish between the create and update flow
        const containerAsin = this.props.match.params.containerAsin;
        if (!containerAsin) {
            this.setState({ ...INITIAL_STATE, loading: false });
            return;
        }
        MousaiClient.search(buildSearchCommentaryForAsinQuery(containerAsin)).then((mousaiData) => {
            if (!mousaiData || !mousaiData.length) {
                this.setState({ doesCommentaryExistForContainerAsin: false, loading: false });
                return;
            }
            // TODO: Distinguish between Track and Album experiences
            const forgeExperiences = convertMousaiDataToForgeExperiences(mousaiData);
            this.setState({
                initialExperiences: forgeExperiences,
                trackCommentaries: forgeExperiences.map((e) => deepCopy(e)),
                loading: false,
                isMousaiExperience: true,
            });
            const [commentaryAsins, areValidAsins] = retrieveAsinsFromMousai(mousaiData[0]);
            this.setState({
                addAlbumCommentaryButtonDisabled: !!this.state.albumCommentary ? true : !areValidAsins,
                addTrackCommentaryButtonDisabled: !areValidAsins,
                canSave: {
                    ...this.state.canSave,
                    areValidAsins: areValidAsins,
                },
                commentaryAsins: commentaryAsins,
            });
        });
    };

    // MARK: Track Commentaries
    addTrackCommentary = () => {
        this.setState({
            trackCommentaries: this.state.trackCommentaries.concat(createNewForgeExperience('TRACK')),
        });
        this.updateSaveButtonStateBasedOnCommentaries();
        this.scrollToBottom();
    };

    onTrackExperienceChanged = (exp: ForgeMusicExperience) => {
        this.setState({
            trackCommentaries: this.state.trackCommentaries.map((commentary) => {
                if (commentary.key === exp.key) {
                    return exp;
                } else {
                    return commentary;
                }
            }),
        });
        this.updateSaveButtonStateBasedOnCommentaries();
    };

    onTrackExperienceDeleted = (exp: ForgeMusicExperience) => {
        // If the experience only exists locally, then we can just delete it from our local array.
        // Else, mark the experience for deletion via API with the `isForDeletion` attribute
        let updatedTrackCommentaries: ForgeMusicExperience[] = [];
        if (exp.isExperienceInMousai) {
            updatedTrackCommentaries = this.state.trackCommentaries.map((commentary) => {
                if (commentary.key == exp.key) {
                    commentary.isForDeletion = true;
                }
                return commentary;
            });
        } else {
            updatedTrackCommentaries = this.state.trackCommentaries.filter((e) => e.key != exp.key);
        }
        this.setState({
            trackCommentaries: updatedTrackCommentaries,
        });
        this.updateSaveButtonStateBasedOnCommentaries();
    };

    // MARK: Album Commentary
    addAlbumCommentary = () => {
        if (!this.state.commentaryAsins.album) {
            return;
        }
        this.setState({
            albumCommentary: createNewForgeExperience('ALBUM', this.state.commentaryAsins.album),
            addAlbumCommentaryButtonDisabled: true,
        });
        this.updateSaveButtonStateBasedOnCommentaries();
        this.scrollToBottom();
    };

    onAlbumExperienceChanged = (exp: ForgeMusicExperience) => {
        this.setState({ albumCommentary: exp });
        this.updateSaveButtonStateBasedOnCommentaries();
    };

    onAlbumExperienceDeleted = (_: ForgeMusicExperience) => {
        this.setState({ albumCommentary: undefined, addAlbumCommentaryButtonDisabled: false });
        this.updateSaveButtonStateBasedOnCommentaries();
    };

    // MARK: Save
    saveAllCommentaries = () => {
        this.setState({ flashbar: [itemLoading('Saving commentary...')] });
        const allCommentaries: ForgeMusicExperience[] = [...this.state.trackCommentaries];
        let successes, failures;
        if (this.state.albumCommentary) {
            allCommentaries.push(this.state.albumCommentary);
        }
        if (!this.state.commentaryAsins.album || !this.state.commentaryAsins.artist) {
            return;
        }
        this.setState({ flashbar: [itemLoading('Saving commentary...')] });
        handleCommentaryFormSave(
            this.state.initialExperiences,
            allCommentaries,
            this.state.commentaryAsins.album,
            this.state.commentaryAsins.artist,
        )
            .then((result) => {
                [successes, failures] = result;
                if (failures.length > 0) {
                    this.buildErrorMessageFlashbar(failures);
                } else {
                    this.buildSaveSuccessfulFlashbar(successes.length);
                }
            })
            .finally(() => {
                this.setState({ isSaveComplete: true });
            });
    };

    buildSaveSuccessfulFlashbar = (count: number) => {
        const type = 'success';
        const header = `Successfully saved ${count} commentaries`;
        const content = (
            <Link to={'/artist-commentaries'}>
                <br />
                <Button>Done</Button>
            </Link>
        );
        this.setState({
            isSaveComplete: true,
            flashbar: [{ type: type, header: header, content: content }],
        });
    };

    buildErrorMessageFlashbar = (failures: MousaiAPIResult[]) => {
        const type = 'error';
        const header = `Failed to save ${failures.length} commentaries`;
        const content = (
            <React.Fragment>
                <ul>
                    {failures.map((e, idx) => {
                        return <li key={idx}>{e.message}</li>;
                    })}
                </ul>
                <Link to={'/artist-commentaries'}>
                    <br />
                    <Button>Done</Button>
                </Link>
            </React.Fragment>
        );
        this.setState({
            flashbar: [{ type: type, header: header, content: content }],
        });
    };

    isSaveButtonDisabled = (): boolean => {
        // Every condition in `canSave` must be true for the user to save the experience
        return !Object.values(this.state.canSave).every((item) => item === true);
    };

    updateSaveButtonStateBasedOnCommentaries = () => {
        let areAllCommentariesValid =
            this.state.trackCommentaries.length > 0 || this.state.albumCommentary !== undefined;
        for (const exp of this.state.trackCommentaries) {
            if (!isExperienceEligibleToSave(exp)) {
                areAllCommentariesValid = false;
                break;
            }
        }
        const albumCommentary = this.state.albumCommentary;
        // Short-circuit: if any of the track commentaries are invalid, then we can't save all anyway
        if (areAllCommentariesValid && albumCommentary !== undefined) {
            areAllCommentariesValid = isExperienceEligibleToSave(albumCommentary);
        }
        this.setState({
            canSave: {
                ...this.state.canSave,
                areAllCommentariesValid: areAllCommentariesValid,
            },
        });
    };

    // MARK: ArtistCommentaryAsinForm prop methods
    onAsinChanged = (asins: CommentaryAsins, areValidAsins: boolean) => {
        this.setState({
            addAlbumCommentaryButtonDisabled: !!this.state.albumCommentary ? true : !areValidAsins,
            addTrackCommentaryButtonDisabled: !areValidAsins,
            canSave: {
                ...this.state.canSave,
                areValidAsins: areValidAsins,
            },
            commentaryAsins: deepCopy(asins),
        });
    };

    setAlbumImageFile = (file: File) => {
        this.setState({ albumImageFile: file });
    };

    // MARK: Miscellaneous
    undoChanges = () => {
        this.setState({
            trackCommentaries: this.state.initialExperiences.map((e) => deepCopy(e)),
        });
        this.updateSaveButtonStateBasedOnCommentaries();
    };

    scrollToBottom = () => {
        this.bottomOfPageRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    render() {
        const headerButtons: PageHeaderButton[] = [
            {
                text: 'Save',
                icon: 'upload',
                disabled: this.isSaveButtonDisabled(),
                loading: this.state.loading,
                variant: 'primary',
                onClick: this.saveAllCommentaries,
            },
            {
                text: 'Track Commentary',
                icon: 'add-plus',
                disabled: this.state.addTrackCommentaryButtonDisabled,
                loading: this.state.loading,
                onClick: this.addTrackCommentary,
            },
            {
                text: 'Album Commentary',
                icon: 'add-plus',
                disabled: this.state.addAlbumCommentaryButtonDisabled,
                loading: this.state.loading,
                onClick: this.addAlbumCommentary,
            },
        ];
        if (this.props.match?.params?.containerAsin) {
            headerButtons.splice(1, 0, {
                text: 'Undo',
                icon: 'undo',
                loading: this.state.loading,
                variant: 'primary',
                onClick: this.undoChanges,
            });
        }

        const commentaryForm: JSX.Element = (
            <React.Fragment>
                <Flashbar items={this.state.flashbar} />
                <PageHeader text={'Create Artist Commentaries'} buttons={headerButtons} />
                <Form>
                    <div className="awsui-grid">
                        <ArtistCommentaryAsinForm
                            asins={this.state.commentaryAsins}
                            isReadonly={this.state.isMousaiExperience}
                            onAsinChanged={this.onAsinChanged}
                            setAlbumImageFile={this.setAlbumImageFile}
                        />
                        <ArtistCommentaryFormManager
                            albumImageFile={this.state.albumImageFile}
                            albumCommentary={this.state.albumCommentary}
                            trackCommentaries={this.state.trackCommentaries}
                            onAlbumExperienceChanged={this.onAlbumExperienceChanged}
                            onAlbumExperienceDeleted={this.onAlbumExperienceDeleted}
                            onTrackExperienceChanged={this.onTrackExperienceChanged}
                            onTrackExperienceDeleted={this.onTrackExperienceDeleted}
                        />
                    </div>
                    <div ref={this.bottomOfPageRef} />
                </Form>
            </React.Fragment>
        );

        const loadingSpinner: JSX.Element = (
            <span className="awsui-util-status-inactive">
                <Spinner /> Loading
            </span>
        );

        const failedToFetchFlashbar: JSX.Element = (
            <Flashbar
                items={[
                    itemError(`Failed to fetch commentary data for album: "${this.props.match.params.containerAsin}"`),
                ]}
            />
        );

        if (this.state.isSaveComplete) {
            return <Flashbar items={this.state.flashbar} />;
        }

        return (
            <React.Fragment>
                {this.state.loading
                    ? loadingSpinner
                    : this.state.doesCommentaryExistForContainerAsin
                    ? commentaryForm
                    : failedToFetchFlashbar}
            </React.Fragment>
        );
    }
}
